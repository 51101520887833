import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout/Layout"
import Article from "../../../components/Article/Article"
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs"
import i18n from "i18next"
import A from "../../../components/FastLink/A"

const SeoArticle = ({ data }) => {
  useEffect(() => {
    i18n.changeLanguage("pl")
  }, [])
  return (
    <Layout
      seo={{
        title: "Nagłówki w artykułach internetowych. Czym są i do czego służą?",
        description:
          "H1, H2 i H3 to najpopularniejsze nagłówki na stronach internetowych. Dowiedz się, jak powinny wyglądać, ile ich powinno być i czy mają wpływ na działania SEO.",
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/baza-wiedzy/artykuly/",
                name: "Baza wiedzy",
              },
            },
            {
              "@type": "ListItem",
              position: 3,
              item: {
                "@id": "/baza-wiedzy/artykul/czym-sa-naglowki-h1-h2-h3/",
                name: "Nagłówki w artykułach internetowych. Czym są i do czego służą?",
              },
            },
          ],
        },
      }}
    >
      <Breadcrumbs
        path={[
          { url: "/", text: "Główna" },
          {
            url: "/baza-wiedzy/artykuly/",
            text: "Baza wiedzy / Artykuły",
          },
          {
            url: "/baza-wiedzy/artykul/",
            text: "Czym są nagłówki H1, H2, H3?",
          },
        ]}
      />
      <Article img={<img src={data.image1.childImageSharp.fluid.src} alt={"Czym są nagłówki H1, H2, H3?"} />}>
        <h1>Czym są nagłówki H1-H6?</h1>
        <p>
          <strong>
            Nagłówki w artykułach publikowanych w prasie pozwalają czytelnikom zorientować się w tekście. A poza tym
            pozytywnie wpływają na przejrzystość treści. W przypadku materiałów internetowych jest podobnie, lecz z co
            najmniej jedną różnicą – nagłówki różnej wielkości (tag H1-H6) i ich zawartość oddziałują na pozycję danego
            artykułu w wynikach wyszukiwania, tj. wpływają na SEO.
          </strong>
        </p>
        <h2>Dlaczego warto używać nagłówków H1, H2 i H3?</h2>
        <p>
          Nagłówki, które nazywa się również śródtytułami, służą do nazywania fragmentów tekstu, pomagają go porządkować
          i ułatwiają odbiór czytelnikowi. Ustawione są hierarchicznie, od najważniejszego H1 (tag H1, czyli nagłówek
          główny) do kolejnych stopniowo mniej istotnych H2, H3 aż do H6 (najczęściej korzysta się tylko z trzech
          pierwszych typów).
        </p>
        <p>
          Poza użytkownikami nagłówki służą także robotom wyszukiwarek. Dzięki nim rozpoznają tematykę tekstu i dokonują
          klasyfikacji. M.in. dlatego warto używać w śródtytułach słów kluczowych, lecz w sposób naturalny i przystępny
          dla czytelników.
        </p>
        <h2>Treść nagłówka – jak powinna wyglądać?</h2>
        <p>
          Powinny być przede wszystkim atrakcyjne dla odbiorców oraz krótkie i precyzyjnie opisujące daną treść. Nie
          warto stosować tych samych słów kluczowych w kolejnych nagłówkach, lepiej zastąpić je synonimami lub wyrazami
          bliskoznacznymi. Słusznym pomysłem jest również postawienie na unikalność. Zmniejszy to liczbę konkurentów i
          pozwoli lepiej wypozycjonować daną treść.
        </p>
        <h3>Treść nagłówka – wskazówka</h3>
        <p>
          Niektórzy specjaliści SEO sugerują również, żeby słowa kluczowe starać się umieszczać na początku nagłówka.
        </p>
        <h2>Tworzenie nagłówków</h2>
        <h3>Ile nagłówków H1 umieścić na stronie?</h3>
        <p>
          Liczba zależy od treści. Koniecznością jest tytuł (H1). Nagłówek H1 powinien opisywać całość, zawierać słowa
          kluczowe i zachęcić użytkownika do zapoznania się z zawartością artykułu. Zazwyczaj używany jest jednokrotnie,
          na początku tekstu.
        </p>
        <p>
          Niższe poziomy nazywają poszczególne fragmenty artykułu. Im jest on dłuższy, tym więcej śródtytułów będzie
          potrzebnych, aby zachować przejrzystość całości. Szacuje się, że w tekście, który składa się z 3-4 tys. znaków
          ze spacjami powinny znaleźć się minimum dwa nagłówki H2.
        </p>
        <h3>Hierarchia nagłówków</h3>
        <p>
          Poziom nagłówka powinien odpowiadać jego pozycji w tekście. Nagłówki H3 nazywają mniejsze części fragmentu
          oznaczonego nagłówkiem H2.
        </p>
        <h3>Jak wygląda nagłówek – przykład struktury nagłówków</h3>
        <p>
          Poniżej przedstawiamy fragment artykuły (nie jest na nim widoczny nagłówek H1) składającego się z nagłówków H2
          i H3:
        </p>
        <figure>
          <img src={data.image2.childImageSharp.fluid.src} alt={"Czym są nagłówki H1, H2, H3?"} />
          <figcaption>
            Źródło: <A to="https://www.panoramafirm.pl" text={"panoramafirm.pl"} />
          </figcaption>
        </figure>
        <p>
          Na powyższym screenie można zauważyć, że nagłówek H2, czyli „Jak zbudować pałac pamięci?”, składa się z
          czterech nagłówków H3 tj. (w tym przypadku) z poszczególnych kroków, które odpowiadają na tytułowe pytanie.
        </p>
        <h2>Czy nagłówki wpływają na wyniki wyszukiwania?</h2>
        <p>
          Poza tym, że dobrze skonstruowane nagłówki mają pozytywny wpływ na SEO (treść umieszczona w nagłówkach może
          odpowiadać na zapytania internautów, a dzięki temu dany adres wyświetli się w wynikach wyszukiwania), to
          również poprawia sam sposób wyświetlania się strony w wyszukiwarce.
        </p>
        <h2>Jak zoptymalizować nagłówki strony?</h2>
        <h3>Dostosowanie nagłówków do urządzeń mobilnych</h3>
        <p>
          Coraz więcej osób korzysta z Internetu na urządzeniach mobilnych. Dlatego{" "}
          <strong>warto zadbać o to, aby nagłówki H były odpowiednio dostosowane do mniejszych ekranów</strong>. Można
          to osiągnąć poprzez stosowanie elastycznych jednostek miary (np. procentów) oraz dbanie o odpowiednie
          formatowanie tekstu. Dzięki temu Twoja strona będzie czytelna i atrakcyjna dla użytkowników niezależnie od
          urządzenia, na którym jest wyświetlana.
        </p>
        <h3>Analiza konkurencji i optymalizacja nagłówków</h3>
        <p>
          Aby w pełni wykorzystać potencjał nagłówków H, warto przyjrzeć się stronom konkurencji i zobaczyć, jak one
          stosują te elementy. Analiza konkurencyjnych stron pomoże Ci zrozumieć, jakie słowa kluczowe są najbardziej
          wartościowe w Twojej branży oraz jakie struktury nagłówków sprawdzają się najlepiej.
        </p>
        <p>
          Na podstawie tych informacji możesz zoptymalizować swoją stronę, by lepiej spełniała oczekiwania użytkowników
          i wyszukiwarek.
        </p>
        <h2>Optymalizacja nagłówków – monitorowanie efektów</h2>
        <p>
          Stosując nagłówki H w praktyce, warto regularnie monitorować efekty swoich działań. Narzędzia analityczne,
          takie jak Google Analytics czy Search Console, pozwolą Ci śledzić, jak zmienia się pozycja Twojej strony w
          wynikach wyszukiwania oraz jakie słowa kluczowe generują najwięcej ruchu.
        </p>
        <p>
          Na podstawie tych danych możesz modyfikować swoje nagłówki, by jeszcze lepiej dopasować się do potrzeb
          użytkowników i wymagań algorytmów wyszukiwarek.
        </p>
        <h2>Wpływ nagłówków na SEO – aktualizacja i rozwój</h2>
        <p>
          Pamiętaj, że optymalizacja nagłówków to proces ciągły, który nie kończy się wraz z publikacją strony. W miarę
          jak Twoja firma się rozwija, a rynek ulega zmianom, warto regularnie aktualizować treści na stronie oraz
          dostosowywać nagłówki do nowych potrzeb. Dzięki temu będziesz mógł utrzymać wysoką pozycję w Google i zyskać
          przewagę nad konkurencją.
        </p>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  {
    image1: file(relativePath: { eq: "img/full/naglowki.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
    image2: file(relativePath: { eq: "img/full/pf-image1.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
  }
`

export default SeoArticle
